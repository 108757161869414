import React from "react"

function ArrowDownIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.18005 6.08001L8.18005 12.31L8.18005 17.92C8.18005 18.88 9.34005 19.36 10.0201 18.68L15.2001 13.5C16.0301 12.67 16.0301 11.32 15.2001 10.49L13.2301 8.52001L10.0201 5.31001C9.34005 4.64001 8.18005 5.12001 8.18005 6.08001Z"
        fill="#2C2C2C"
      />
    </svg>
  )
}

export default ArrowDownIcon
