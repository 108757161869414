import { useQuery, useQueryClient } from "@tanstack/react-query"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import {
  NewResidentalComplexModel,
  ResidentialComplexModel,
} from "../../../utils/models/residentialComplexModel"
import { ErrorRes } from "../../../utils/models/responseType"
import { queryKeys } from "../utils/constants/queryKeys"
import { PaymentMethodType } from "../utils/models/paymentMethodType"
import {
  fetchBanners,
  fetchComplexesOfBuilder,
  fetchNewComplexesOfBuilder,
  fetchNewSimilarComplexes,
  fetchPaymentMethods,
  fetchSimilarComplexes,
} from "./queryFunctions"
import { BannerObjectType } from "../utils/models/banner"
import axios from "axios"
import { urls } from "../../../utils/constants/urls"
import { endpoints } from "../utils/constants/endpoints"
import { Pagination } from "utils/models/pagination"

// get similar complexes
export function useGetSimilarComplexes(id: string, type: string) {
  return useQuery<ResidentialComplexModel[], ErrorRes>({
    queryKey: [queryKeys.SIMILAR_COMPLEXES, id],
    queryFn: () => fetchSimilarComplexes({ type, id }),
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}
export function useGetNewSimilarComplexes(id: string) {
  return useQuery<NewResidentalComplexModel[], ErrorRes>({
    queryKey: [queryKeys.NEW_SIMILAR_COMPLEXES, id],
    queryFn: () => fetchNewSimilarComplexes(id),
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}

// get complexes of builder
export function useGetComplexesOfBuilder(id: string, type: string) {
  return useQuery<ResidentialComplexModel[], ErrorRes>({
    queryKey: [queryKeys.COMPLEXES_OF_BUILDER, id],
    queryFn: () => fetchComplexesOfBuilder({ id, type }),
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}
export function useGetNewComplexesOfBuilder(id: string) {
  return useQuery<Pagination<NewResidentalComplexModel>, ErrorRes>({
    queryKey: [queryKeys.NEW_COMPLEXES_OF_BUILDER, id],
    queryFn: () => fetchNewComplexesOfBuilder(id),
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}

// get payment methods for select
export function useGetPaymentMethods() {
  return useQuery<PaymentMethodType[], ErrorRes>({
    queryKey: [queryKeys.PAYMENT_METHODS_SELECT],
    queryFn: fetchPaymentMethods,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}

// get banners
export function useBanners(page_type?: string, lang?: string) {
  return useQuery<BannerObjectType, ErrorRes>({
    queryKey: [queryKeys.BANNER({ lang, page_type })],
    queryFn: () => fetchBanners({ page_type, lang }),
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
  })
}

export function useBannerSendView(uuid?: string) {
  const qc = useQueryClient()
  return useQuery({
    queryKey: [queryKeys.BANNER_VIEW(uuid)],
    queryFn: async () => {
      if (qc.getQueryData([queryKeys.BANNER_VIEW(uuid)])) {
        const res = await Promise.all([])
        return res
      } else {
        const res = await axios.get(
          urls.BASE_ADS_UYSOT + endpoints.API_BANNER_VIEW_UUID(uuid)
        )
        return res.data
      }
    },
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}
