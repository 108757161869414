import { Button } from "antd"
import { useTranslations } from "next-intl"
import { useRouter } from "next/router"
import React, { useEffect, useMemo } from "react"

import ComplexCard from "../../../../common/common-card/complex/ComplexCard"
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks"
import { userReducerActions } from "../../../../store/reducers/userReducer"
import { rootPaths } from "../../../../utils/constants/rootPaths"
import { viewTypes } from "../../../../utils/constants/viewTypes"
import { useGetTopResidentialComplexes } from "../../services/queries"

import styles from "./NewComplexes.module.scss"
import NonSSRWrapper from "../../../../common/non-ssr/NonSSRWrapper"
import { useBanners } from "../../../app/services/queries"
import ViewAdv from "../../../../common/view-adv/ViewAdv"

const MainNewComplexes: React.FC = () => {
  const t = useTranslations("body.newComplexes")
  const tMainTop = useTranslations("body.mainTop")
  const { push, locale } = useRouter()
  const dispatch = useAppDispatch()
  const { setUserAddress } = userReducerActions
  const { userAddress } = useAppSelector((state) => state.userReducer)
  const { data } = useGetTopResidentialComplexes()
  const { data: banners } = useBanners("main", locale)

  // console.log("data:", data, "districtid:", userAddress?.district_id)
  useEffect(() => {
    dispatch(setUserAddress(userAddress))
  }, [userAddress])

  // location
  const location = useMemo(() => userAddress?.url_name, [userAddress])

  // to residential complexes
  const toResidentialComplexes = () => {
    push(`/${location}${rootPaths.RESIDENTIAL_COMPLEXES}`)
  }

  return (
    <section className={styles.new_buildings}>
      <div className="wrapper">
        <h2 className={styles.title}>{t("Популярные новостройки")}</h2>
        <div className={styles.body}>
          {banners?.right_side?.images?.web?.url && (
            <ViewAdv uuid={banners?.right_side?.uuid}>
              <a
                className={styles.reklama_right}
                target={banners?.right_side?.blank ? "_blank" : "_self"}
                href={`https://services.marketplace.uysot.uz/api/banner/view?uuid=${banners?.right_side?.uuid}&lang=${locale}`}
              >
                <div className={styles.reklama_tag}>
                  <span>{tMainTop("Реклама")}</span>
                </div>
                <img src={banners?.right_side?.images?.web?.url} alt="" />
              </a>
            </ViewAdv>
          )}
          {data?.slice(0, 6)?.map((item) => (
            <NonSSRWrapper>
              <ComplexCard
                data={item}
                key={item?.id}
                viewType={viewTypes.GRID}
              />
            </NonSSRWrapper>
          ))}
        </div>
        <div className={styles.bottom}>
          <Button onClick={toResidentialComplexes}>{t("Показать еще")}</Button>
        </div>
      </div>
    </section>
  )
}

export default MainNewComplexes
