import { Button, Checkbox, Form, Grid, Input } from "antd"
import { useTranslations } from "next-intl"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { useMemo } from "react"

import ArrowDownIcon from "../../../../assets/icons/ArrowDownIcon"
import ArrowRightIcon from "../../../../assets/icons/ArrowRightIcon"
import LocationIcon from "../../../../assets/icons/LocationIcon"
import { useAppSelector } from "../../../../hooks/reduxHooks"
import { rootPaths } from "../../../../utils/constants/rootPaths"
// import { openMap } from "../../../../utils/helpers/openMap"
import { useGetCounts } from "../../services/queries"
import SearchIcon from "../../../../assets/icons/SearchIcon"

import style from "./MainTop.module.scss"
import { useBanners } from "../../../app/services/queries"
import ViewAdv from "../../../../common/view-adv/ViewAdv"
import Image from "next/image"
type FormFields = {
  rooms_count?: string
  complex_name?: string
}

const MainTop: React.FC = () => {
  const t = useTranslations("body.mainTop")
  const router = useRouter()
  const { data: counts } = useGetCounts()
  const { data: banners, isLoading } = useBanners("main", router.locale)
  const [formInstance] = Form.useForm()
  const { md } = Grid.useBreakpoint()
  const { userAddress } = useAppSelector((state) => state.userReducer)

  // location
  const location = useMemo(() => userAddress?.url_name, [userAddress])

  // rooms count
  const roomsCount = [
    {
      label: t("Студия"),
      value: "0",
    },
    {
      label: "1",
      value: "1",
    },
    {
      label: "2",
      value: "2",
    },
    {
      label: "3",
      value: "3",
    },
    {
      label: "4+",
      value: "4",
    },
  ]

  // on finish
  const onFinish = (fields: FormFields) => {
    const queryParams: FormFields = {}
    let key: keyof typeof fields

    for (key in fields) {
      fields[key] && (queryParams[key] = fields[key])
    }

    router.push({
      pathname: `/${location}${rootPaths.APARTMENTS}`,
      query: {
        ...queryParams,
      },
    })
  }

  return (
    <section
      className={style.main_top}
      style={{
        backgroundImage:
          isLoading || banners?.top?.images?.web?.url ? "none" : "",
      }}
    >
      <div
        className="wrapper d_f fd_c ai_c p_r"
        style={{
          backgroundImage:
            isLoading || banners?.top?.images?.web?.url ? "none" : "",
        }}
      >
        {md && banners?.top?.images?.web?.url ? (
          <ViewAdv uuid={banners?.top?.uuid}>
            <a
              className={style.reklama}
              target={banners?.top?.blank ? "_blank" : "_self"}
              href={`https://services.marketplace.uysot.uz/api/banner/view?uuid=${banners?.top?.uuid}&lang=${router.locale}`}
            >
              <div className={style.reklama_tag}>
                <span>{t("Реклама")}</span>
              </div>
              <Image src={banners?.top?.images?.web?.url || ""} fill alt="" />
            </a>
          </ViewAdv>
        ) : (
          <></>
        )}
        {/* {locale === RU ? (
          <a
            href={`https://expo.uysot.uz/ru/?utm_source=uysot_uz&utm_medium=site&utm_campaign=banner`}
            target="_blank"
            style={{ marginTop: "50px", marginBottom: "15px" }}
          >
            <Image className={style.expoBanner} src={Expo} alt="ExpoRu" />
          </a>
        ) : (
          <a
            href={`https://expo.uysot.uz/uz/?utm_source=uysot_uz&utm_medium=site&utm_campaign=banner`}
            target="_blank"
            style={{ marginTop: "50px", marginBottom: "15px" }}
          >
            <Image className={style.expoBanner} src={ExpoUZ} alt="ExpoUz" />
          </a>
        )} */}
        <div
          className={style.main_top_top}
          style={{
            backgroundImage:
              isLoading || banners?.top?.images?.web?.url ? "none" : "",
          }}
        >
          {!md &&
          (banners?.top?.images?.web?.url ||
            banners?.top?.images?.mobile?.url) ? (
            <ViewAdv uuid={banners?.top?.uuid}>
              <a
                className={style.reklama}
                target={banners?.top?.blank ? "_blank" : "_self"}
                href={`https://services.marketplace.uysot.uz/api/banner/view?uuid=${banners?.top?.uuid}&lang=${router.locale}`}
              >
                <div className={style.reklama_tag}>
                  <span>{t("Реклама")}</span>
                </div>
                <img
                  src={
                    banners?.top?.images?.mobile?.url ||
                    banners?.top?.images?.web?.url ||
                    ""
                  }
                  alt=""
                />
              </a>
            </ViewAdv>
          ) : (
            <></>
          )}
          <h1>{t("Найди дом своей мечты прямо сейчас")}</h1>
          {/* <NonSSRWrapper> */}
          {/* {!md && ( */}
          {/* <Link
            href={`/gorod-tashkent${rootPaths.RESIDENTIAL_COMPLEXES}/musaffo-1`}
            legacyBehavior
          >
            <a
              className={`${style.main_top_bottom_header} mobileD`}
              aria-hidden
              suppressHydrationWarning
            >
              <div className={style.left}>
                <p>Art House</p>
                <span>{t("ЖК")} «Musaffo»</span>
                <ArrowRightIcon />
              </div>
              <span className={style.right}>{t("Реклама")}</span>
            </a>
          </Link> */}
          {/* )} */}
          {/* </NonSSRWrapper> */}
          {/* <NonSSRWrapper> */}
          {/* {md && ( */}
          <Form
            className={style.form}
            onFinish={onFinish}
            autoComplete="off"
            form={formInstance}
          >
            <div className={style.form_left}>
              <div className={style.form_items}>
                <Form.Item name="rooms_count" className={style.rooms_count}>
                  <Checkbox.Group>
                    {roomsCount?.map((item, index) => (
                      <div className="d_f" key={item.value}>
                        <Checkbox value={item.value}>
                          <span
                            className={`${style.rooms_count_item} room_type`}
                          >
                            {item.label}
                          </span>
                        </Checkbox>
                        <span
                          className={`${style.border} ${
                            index === roomsCount?.length - 1 ? style.last : ""
                          }`}
                        />
                      </div>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item className={style.price} name="complex_name">
                  <Input
                    placeholder={t("Жилой комплекс")}
                    prefix={
                      <SearchIcon size="20" color="rgba(163, 166, 183, 1)" />
                    }
                    className={style.price_input}
                  />
                </Form.Item>
                {/* <Form.Item name="min_total_price" className={style.price}>
                      <Input
                        placeholder={t("от")}
                        suffix="UZS"
                        className={style.price_input}
                        onChange={() => handlePriceFields("min_total_price")}
                      />
                    </Form.Item>
                    <Form.Item name="max_total_price" className={style.price}>
                      <Input
                        placeholder={t("до")}
                        suffix="UZS"
                        className={style.price_input}
                        onChange={() => handlePriceFields("max_total_price")}
                      />
                    </Form.Item> */}
              </div>
              {/* <div className={style.tags}>
                    <Form.Item name="year">
                      <Checkbox.Group>
                        <div className="d_f">
                          <Checkbox
                            value={dayjs().format(dayjsFormats.DEFAULT_DATE)}
                          >
                            <span className={`${style.tag} year`}>
                              {t("Готовые новостройки")}
                            </span>
                          </Checkbox>
                        </div>
                      </Checkbox.Group>
                    </Form.Item>
                    <Form.Item name="payment_method_ids">
                      <Checkbox.Group>
                        {paymentMethods?.map((item, index) => (
                          <div key={index} className="d_f">
                            <Checkbox value={String(item.id)}>
                              <span className={`${style.tag} method`}>
                                {router.locale === UZ
                                  ? item.name.uz
                                  : item.name.ru}
                              </span>
                            </Checkbox>
                          </div>
                        ))}
                      </Checkbox.Group>
                    </Form.Item>
                  </div> */}
            </div>
            <Button className={style.submit} htmlType="submit">
              {t("Найти")}
            </Button>
          </Form>
          {/* )} */}
          {/* </NonSSRWrapper> */}
        </div>
        <div className={style.main_top_bottom}>
          {/* <NonSSRWrapper> */}
          {/* {md && ( */}
          {/* <Link
            href={`/gorod-tashkent${rootPaths.RESIDENTIAL_COMPLEXES}/musaffo-1`}
            legacyBehavior
          >
            <a
              className={`${style.main_top_bottom_header} desktopD`}
              aria-hidden
              suppressHydrationWarning
            >
              <div className={style.left}>
                <p>Art House</p>
                <span>{t("ЖК")} «Musaffo»</span>
                <ArrowRightIcon />
              </div>
              <span className={style.right}>{t("Реклама")}</span>
            </a>
          </Link> */}
          {/* )} */}
          {/* </NonSSRWrapper> */}
          <div className={style.main_top_bottom_body}>
            <Link
              href={`/${location}${rootPaths.RESIDENTIAL_COMPLEXES}`}
              legacyBehavior
            >
              <a className={style.item}>
                <div className={style.top}>
                  <span>{t("Каталог ЖК")}</span>
                  <ArrowDownIcon />
                </div>
                <div className={style.bottom}>
                  <span>{t("Всего объявлений")}</span>
                  <p>{counts?.complexes_count}</p>
                </div>
              </a>
            </Link>
            <Link href={`/${location}${rootPaths.APARTMENTS}`} legacyBehavior>
              <a className={style.item}>
                <div className={style.top}>
                  <span>{t("Квартиры")}</span>
                  <ArrowDownIcon />
                </div>
                <div className={style.bottom}>
                  <span>{t("Всего объявлений")}</span>
                  <p>{counts?.apartments_count}</p>
                </div>
              </a>
            </Link>
            {/* <Link href={rootPaths.BUILDERS} legacyBehavior>
              <a className={style.item}>
                <div className={style.top}>
                  <span>{t("Застройщики")}</span>
                  <ArrowDownIcon />
                </div>
                <div className={style.bottom}>
                  <span>{t("Всего объявлений")}</span>
                  <p>{counts?.builders_count}</p>
                </div>
              </a>
            </Link> */}
            <Link href={rootPaths.MAP} legacyBehavior>
              <a
                className={`${style.item} ${style.map}`}
                // onClick={openMap}
                aria-hidden
              >
                <LocationIcon />
                <span>
                  {t("Показать на")}
                  <br />
                  {t("карте")}
                </span>
              </a>
            </Link>
          </div>
        </div>
      </div>
      {/* <div className={style.main_expoModal}>
        <ExpoModal />
      </div> */}
    </section>
  )
}

export default MainTop
