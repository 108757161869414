import { useQuery } from "@tanstack/react-query"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import {
  NewResidentalComplexModel,
  ResidentialComplexModel,
} from "../../../utils/models/residentialComplexModel"
import { ErrorRes } from "../../../utils/models/responseType"
import { BuilderType } from "../../builders/utils/models/builderModel"
import { MagazineType } from "../../magazines/utils/models/magazineModel"
import { queryKeys } from "../utils/constants/queryKeys"
import { CountsRes } from "../utils/models/basicModel"
import {
  fetchApartmentCounts,
  fetchCounts,
  fetchTopBuilders,
  fetchTopComplexes,
  fetchTopMagazines,
} from "./queryFunctions"
import { useRouter } from "next/router"

// counts
export function useGetCounts() {
  return useQuery<CountsRes, ErrorRes>({
    queryKey: [queryKeys.COUNTS],
    queryFn: fetchCounts,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}

// get top residential complexes
export function useGetTopResidentialComplexes() {
  const { url, fetch } = fetchTopComplexes()

  return useQuery<NewResidentalComplexModel[], ErrorRes>({
    queryKey: [queryKeys.TOP_COMPLEXES, url],
    queryFn: fetch,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
  })
}

// get apartment counts
export function useGetApartmentCounts() {
  return useQuery<
    { rooms_count: number; apartments_count: number }[],
    ErrorRes
  >({
    queryKey: [queryKeys.APARTMENT_COUNTS],
    queryFn: fetchApartmentCounts,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}

// get top builders
export function useGetTopBuilders() {
  return useQuery<BuilderType[], ErrorRes>({
    queryKey: [queryKeys.TOP_BUILDERS],
    queryFn: fetchTopBuilders,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}

// get top magazines
export function useGetTopMagazines() {
  const { locale } = useRouter()
  return useQuery<MagazineType[], ErrorRes>({
    queryKey: [queryKeys.TOP_MAGAZINES, locale],
    queryFn: () => {
      return fetchTopMagazines(locale)
    },
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}
