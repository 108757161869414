import axios from "axios"
import $api, { $newapi } from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endpoints"
import { urls } from "../../../utils/constants/urls"
import {
  BannerImageType,
  BannerObjectType,
  BannerPosition,
  IBanner,
  IBannerImage,
} from "../utils/models/banner"

// payment methods
export const fetchPaymentMethods = async () => {
  const res = await $api.get(endpoints.PAYMENT_METHODS_SELECT)
  return res.data
}

// similar complexes
export const fetchSimilarComplexes = async ({
  type,
  id,
}: {
  type: string
  id: string
}) => {
  const res = await $api.get(`${endpoints.SIMILAR_COMPLEX}?${type}=${id}`)
  return res.data ?? []
}
export const fetchNewSimilarComplexes = async (id: string) => {
  const res = await $newapi.get(`/complex/similar?complex_id=${id}`)
  return res.data.data ?? []
}

// complexes of builder
export const fetchComplexesOfBuilder = async ({
  type,
  id,
}: {
  type: string
  id: string
}) => {
  const res = await $api.get(`${endpoints.COMPLEXES_OF_BUILDER}?${type}=${id}`)
  return res.data ?? []
}
export const fetchNewComplexesOfBuilder = async (id: string) => {
  const res = await $newapi.get(
    `/complex/other-complex-builder?complex_id=${id}`
  )
  return res.data.data ?? []
}

// banners
export const fetchBanners = async ({
  page_type,
  lang,
}: {
  page_type?: string
  lang?: string
}) => {
  const res = await axios.get(
    `${urls.BASE_ADS_UYSOT}${endpoints.API_BANNER_VIEW({ page_type, lang })}`
  )

  const data: IBanner[] = res.data.data

  const convertData = data?.reduce<BannerObjectType>((p, c) => {
    const images = c?.images
      ?.filter((item) => item?.language_code === lang)
      ?.reduce<Record<BannerImageType, IBannerImage>>((imgP, imgC) => {
        imgP[imgC?.type] = imgC
        return imgP
      }, {} as Record<BannerImageType, IBannerImage>)

    p[c.position] = {
      ...c,
      images,
    }
    return p
  }, {} as BannerObjectType)

  return convertData
}
